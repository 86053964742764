<template>
  <!-- if not logined -->
  <v-container class="pa-2" style="max-width: 600px">
    <v-card class="py-5 mt-5" elevation="0">
      <v-card class="pa-2 red lighten-1 white--text text-center" outlined elevation="0">
        <h4 class="mt-3">LINEお友達限定100円割引クーポン</h4>
        <v-card-text class="text-caption white--text pb-1">
          <strong>有効期限</strong>
          2021.12.01-2021.12.31
        </v-card-text>
      </v-card>

      <v-card-text class="text-center px-0 mt-3">
        <!-- <br><br> -->

        ログインされると、
        <br />
        割引券がアカウントに紐付けされ、
        <br />
        次回のお会計に適用されます。
        <!-- 配達やお受取に関しても、<br />LINE公式にてお知らせいたします。<br /> -->
      </v-card-text>

      <!--       <p class="text-center">
        ご購入を完成させるには、MYアカウントでのログインが必要となります<br>
        ご注文や配達に関する通知は<br />LINE公式にてお知らせします<br />
      </p> -->

      <!--       <v-row class="justify-center">
        <v-btn href="https://lin.ee/Q0QoAIw" outlined>
          LINE公式をフォロー
        </v-btn>
      </v-row> -->
    </v-card>

    <!--   <v-img src="../../public/access.jpg"></v-img> -->

    <!-- <p class="mt-5">公式アカウントで配達情報を通知するね！</p> -->

    <!-- <p>ここをクリック！</p> -->

    <!-- TODO: -->
    <!-- change redirect URL -->

    <v-footer
      app
      class="pb-4 pt-3 px-0 green accent-4 white--text"
      style="font-weight: bold; max-width: 600px; margin: 0 auto"
    >
      <!--       <v-row cols="4"></v-row>
      <v-row cols="4" align-self="center" class="ma-0">Login</v-row>
      <v-row cols="4"></v-row> -->
      <v-row class="mt-1 mb-1" @click="login_clicked">
        <v-spacer></v-spacer>
        <v-img max-height="35" max-width="45" src="../../public/line.png"></v-img>
        <span class="pt-2 pl-2 text-caption font-weight-bold">
          LINEでログインして、クーポンを受け取る
        </span>
        <!--         <v-col cols="1" class="pa-0"></v-col>
        <v-col cols="2" class="pa-0">
          <v-img
            max-height="35"
            max-width="45"
            src="../../public/line.png"
          ></v-img
          >
        </v-col>
        <v-col class="pa-0">
          <span class="pt-2 pl-2 text-caption">LINEでログインして、<br>クーポンを受け取る</span>
        </v-col> -->
        <v-spacer></v-spacer>
      </v-row>
    </v-footer>
  </v-container>
</template>

<!-- if logined -->
<!-- redirect -->

<script>
  export default {
    name: 'Login',

    data: () => ({}),
    created() {
      // Coupon
    },
    methods: {
      login_clicked: function () {
        let url =
          'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655963535&redirect_uri=' +
          // +'https%3A%2F%2Fwww.google.com'
          'https%3A%2F%2Fwww.sharemart.shop%2Fredirect' +
          '&max_age=0' +
          '&state=' +
          this.$store.state.login_state +
          '&bot_prompt=aggressive' +
          '&scope=profile%20openid&nonce=09876xyz';

        // TODO: vuex add redirect url
        this.$store.commit('set_login_redirect', {
          login_redirect: '/coupon_back',
        });
        window.location.href = url;
      },
    },
  };
</script>

<style scoped></style>
